import { datadogRum } from "@datadog/browser-rum";
import {
  deduceEnv,
  RELEASE_VERSION,
  PACKAGE_VERSION,
} from "src/constants/api.ts";

datadogRum.init({
  applicationId: "e8cb9095-3172-48be-aa4b-3c14306bd681",
  clientToken: "pub9cacc46d8ad10edea2053fc30ae4ad3d",
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: "datadoghq.com",
  service: "echo-platform",
  env: deduceEnv(),
  // only deployed app has RELEASE_VERSION, PACKAGE_VERSION is only used for local development
  version: RELEASE_VERSION ?? `v${PACKAGE_VERSION}`,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});
