import { Outlet, createFileRoute, redirect } from "@tanstack/react-router";
import { Layout } from "antd";
import { LayoutSider } from "./components/LayoutSider.tsx";
import { LayoutHeader } from "./components/LayoutHeader.tsx";
import NotFound from "src/components/NotFound.tsx";
import { isUserLoggedIn } from "src/utils/user.ts";

export const Route = createFileRoute("/dashboard/_layout")({
  component: LayoutComponent,
  beforeLoad: ({ location }) => {
    if (!isUserLoggedIn()) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  notFoundComponent: () => {
    // TODO: its working in tandem with $404.tsx (some time this shows up and other times other one) but, investigate why
    return <NotFound />;
  },
});

function LayoutComponent() {
  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <LayoutSider />
      <Layout>
        <LayoutHeader />
        <Outlet />
      </Layout>
    </Layout>
  );
}
