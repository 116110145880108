import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import React from "react";
import NotFound from "src/components/NotFound";

interface MyRouterContext {}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
  notFoundComponent: () => <NotFound />,
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === "development"
    ? React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      )
    : () => null; // Render nothing in production

function RootComponent() {
  return (
    <>
      <Outlet />
      <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} />
    </>
  );
}
