// TODO finish to add missing grey color token from antd
export const gray = {
  "gray-1": "#ffffff",
  "gray-2": "#fafafa",
  "gray-3": "#f5f5f5",
  "gray-4": "#f0f0f0",
  "gray-5": "#d9d9d9",
  "gray-6": "#bfbfbf",
};

export const extendedToken = {
  ...gray,
  borderRadiusHighPX: "10000px",
  margin2XL: 48,
  margin3XL: 60,
  margin4XL: 72,
  margin5XL: 84,
  margin6XL: 96,

  padding2XL: 48,
  padding3XL: 60,
  padding4XL: 72,
  padding5XL: 84,
  padding6XL: 96,
};
