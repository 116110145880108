import Icon from "@ant-design/icons";
import type { GetProps } from "antd";

type CustomIconComponentProps = GetProps<typeof Icon>;

const GeoPersonaSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 52 52"
      fill="none"
    >
      <circle cx="27" cy="23" r="7" stroke="currentColor" strokeWidth="3" />
      <path
        d="M27 34C21.09 34 15.7787 37.1084 12.1174 40.6333C8.13871 44.4638 11.4772 49 17 49H37C42.5228 49 45.8613 44.4638 41.8826 40.6333C38.2213 37.1084 32.91 34 27 34Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M12 23C12 20.2386 9.76142 18 7 18C9.76142 18 12 15.7614 12 13C12 15.7614 14.2386 18 17 18C14.2386 18 12 20.2386 12 23Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <path
        d="M37 14C37 10.6863 34.3137 8 31 8C34.3137 8 37 5.31371 37 2C37 5.31371 39.6863 8 43 8C39.6863 8 37 10.6863 37 14Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const GeoPersonaIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={GeoPersonaSvg} {...props} />
);
