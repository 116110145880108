import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

export const demographyAgeGroupInhabitantSchema = z.object({
  households: z.array(z.number()).length(2).optional(),
  inhabitants: z.array(z.number()).length(2).optional(),
  inhabitants_male: z.array(z.number()).length(2).optional(),
  inhabitants_female: z.array(z.number()).length(2).optional(),
  pop_0_to_20_female: z.array(z.number()).length(2).optional(),
  pop_0_to_20_male: z.array(z.number()).length(2).optional(),
  pop_20_to_35_female: z.array(z.number()).length(2).optional(),
  pop_20_to_35_male: z.array(z.number()).length(2).optional(),
  pop_35_to_45_female: z.array(z.number()).length(2).optional(),
  pop_35_to_45_male: z.array(z.number()).length(2).optional(),
  pop_45_to_60_female: z.array(z.number()).length(2).optional(),
  pop_45_to_60_male: z.array(z.number()).length(2).optional(),
  pop_60_and_older_female: z.array(z.number()).length(2).optional(),
  pop_60_and_older_male: z.array(z.number()).length(2).optional(),
  pop_age_0_to_20: z.array(z.number()).length(2).optional(),
  pop_age_20_to_35: z.array(z.number()).length(2).optional(),
  pop_age_35_to_45: z.array(z.number()).length(2).optional(),
  pop_age_45_to_60: z.array(z.number()).length(2).optional(),
  pop_age_60_and_older: z.array(z.number()).length(2).optional(),
});

export const demographyPurchasingPowerSchema = z.object({
  purchasing_power_per_inhabitant: z.array(z.number()).length(2).optional(),
  purchasing_power_per_household: z.array(z.number()).length(2).optional(),
});

export const affinityIndexSchema = z.object({
  affinity_index_national: z.array(z.number()).length(2).optional(),
  affinity_index_regional: z.array(z.number()).length(2).optional(),
});

export const basicSchema = z.object({
  // essential filters
  country_code: z.string().optional(),
  country_bbox: z
    .tuple([z.number(), z.number(), z.number(), z.number()])
    .optional(),
  region_name: z.string().optional(),
  region_code: z.string().optional(),
  region_bbox: z
    .tuple([z.number(), z.number(), z.number(), z.number()])
    .optional(),
  geopersona_segment_code: z.string().optional(),
  geopersona_type: z.string().optional(),
  postcode: z.string().optional(),
  postcode_bbox: z
    .tuple([z.number(), z.number(), z.number(), z.number()])
    .optional(),
});

export const extraSchema = z.object({
  granularity: z.string().optional(),
});

export const basicQuerySchema = basicSchema
  .pick({ country_code: true, geopersona_segment_code: true })
  .required()
  .merge(basicSchema.pick({ postcode: true, region_code: true }));

export const validateSearch = basicSchema
  .merge(demographyAgeGroupInhabitantSchema)
  .merge(demographyPurchasingPowerSchema)
  .merge(affinityIndexSchema)
  .merge(extraSchema);

export const Route = createFileRoute("/dashboard/_layout/geopersona/map-view")({
  validateSearch,
});
