import { useNavigate } from "@tanstack/react-router";
import { Button, Result } from "antd";

function NotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    if (navigate) void navigate({ to: "/" });
  };
  return (
    <Result
      status="404"
      title="404"
      subTitle="Page not found."
      extra={
        <Button onClick={goHome} type="primary">
          Back Home
        </Button>
      }
    />
  );
}

export default NotFound;
