export const API_URL = import.meta.env.VITE_API_URL
  ? (import.meta.env.VITE_API_URL as string)
  : deduceApiUrl(window.location.hostname);

console.log("API_URL", API_URL);

function deduceApiUrl(hostname: string): string {
  const parts = hostname.split(".");
  parts[0] = `${window.location.protocol}//api-demo`;
  return parts.join(".") + "/";
}

export const RELEASE_VERSION = import.meta.env.VITE_APP_CONTAINER_IMAGE_TAG as
  | string
  | undefined;

export const PACKAGE_VERSION = import.meta.env.PACKAGE_VERSION as string;

export function deduceEnv() {
  if (isLocalRunTime) return "localhost";
  else if (isDevRunTime) return "dev";
  else if (isStagingRunTime) return "staging";
  return "prod";
}

export const isLocalRunTime =
  window.location.hostname.split(".")[0] === "localhost";
export const isDevRunTime = window.location.hostname.split(".").includes("dev");

export const isStagingRunTime = window.location.hostname
  .split(".")
  .includes("staging");

function getTenant() {
  const hostname = window.location.hostname.replace("www.", "");
  const splitHostname = hostname.split(".");
  const subdomain = splitHostname[0];
  if (subdomain === "localhost") return "demo";
  if (
    splitHostname.length === 2 ||
    subdomain === "dev" ||
    subdomain === "staging"
  )
    return "";
  return subdomain;
}

export const tenantId = getTenant();
