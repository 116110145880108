import { createFileRoute, redirect } from "@tanstack/react-router";
import { checkUserRoles } from "src/utils/user.ts";

export const Route = createFileRoute("/dashboard/_layout/integrations")({
  beforeLoad: (opts) => {
    if (
      checkUserRoles(["root", "admin", "user"]) &&
      opts.location.pathname.indexOf("users") === -1
    ) {
      throw redirect({
        to: "/dashboard/integrations/users",
      });
    } else if (
      checkUserRoles(["trial"]) &&
      opts.location.pathname.indexOf("users") > -1
    ) {
      throw redirect({
        to: "/dashboard/integrations",
      });
    }
  },
});
