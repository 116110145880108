import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";

export const Route = createFileRoute(
  "/dashboard/_layout/geopersona/segment-list",
)({
  validateSearch: z.object({
    status: z.enum(["sent", "saved"]).optional(),
  }),
  beforeLoad: ({ location }) => {
    if (
      !("status" in location.search) ||
      ["sent", "saved"].indexOf(location.search.status as string) === -1
    ) {
      throw redirect({
        search: {
          status: "sent",
        },
      });
    }
  },
});
