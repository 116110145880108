import React from "react";
import { Col, ConfigProvider, Flex, Image, Row } from "antd";
import { useCustomToken } from "src/hooks/useCustomTheme.ts";
import logo from "src/assets/logo.svg";
import auth_bg from "src/assets/auth_bg.jpg";

const authTheme = {
  token: {
    colorTextBase: "#FFFFFF",
  },
  components: {
    Input: {
      colorText: "rgba(0, 0, 0, 0.88)",
    },
  },
};

type LandingLayoutProps = {
  children: React.ReactNode;
};

export function LandingLayout({ children }: LandingLayoutProps) {
  const token = useCustomToken();

  return (
    <ConfigProvider
      button={{ style: { width: "100%" } }}
      form={{ style: { width: "100%" } }}
      typography={{
        style: { textAlign: "center", marginBottom: token.marginXL },
      }}
      theme={authTheme}
    >
      <Row
        style={{
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "#001529",
        }}
      >
        <Col xs={24} md={12} lg={11}>
          <Flex
            vertical
            align="center"
            justify="center"
            gap={token.paddingXS}
            style={{
              padding: `${token.sizeXXL * 2}px 15%`,
              height: "100%",
              position: "relative",
            }}
          >
            <Image
              width={"45%"}
              wrapperStyle={{ minWidth: "200px" }}
              src={logo}
              preview={false}
            />
            {children}
          </Flex>
        </Col>
        <Col xs={0} md={12} lg={13}>
          <Image
            height={"100%"}
            wrapperStyle={{ minHeight: "100vh" }}
            width={"100%"}
            style={{ objectFit: "cover" }}
            src={auth_bg}
            preview={false}
          />
        </Col>
      </Row>
    </ConfigProvider>
  );
}
