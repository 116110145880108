import { ErrorComponentProps } from "@tanstack/react-router";
import { Button, Result } from "antd";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { useCustomToken } from "src/hooks/useCustomTheme.ts";

export function ErrorComponent({ error, info }: ErrorComponentProps) {
  const token = useCustomToken();

  const onReset = () => {
    console.log(error, info);
    // @ts-expect-error true is needed only for firefox
    window.location.reload(true);
  };

  return (
    <>
      <Result
        title="Something went wrong!"
        extra={
          <Button type="primary" onClick={onReset}>
            Reload
          </Button>
        }
        icon={<ExclamationCircleTwoTone twoToneColor={token.red5} />}
      >
        <p>{error?.stack}</p>
        <p>{info?.componentStack}</p>
      </Result>
    </>
  );
}
