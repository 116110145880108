import { Routes } from "src/type.ts";

export function getTitle(url: Routes) {
  switch (url) {
    case "/dashboard/geopersona/map-view":
      return "GeoPersona / Map View";
    case "/dashboard/geopersona/segment-list":
      return "GeoPersona / Segment List";

    case "/dashboard/integrations":
    case "/dashboard/integrations/users":
      return "Integrations";
    case "/dashboard/integrations/tradedesk":
      return "Integrations / The Trade Desk";

    case "/dashboard/settings":
      return "Settings";

    default:
      return "Dashboard";
  }
}
