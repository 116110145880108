import axios from "axios";
import { Role, User } from "src/type.ts";

export function getUser() {
  return JSON.parse(localStorage.getItem("user") || "{}") as User;
}

export function loginUser(user: User) {
  localStorage.setItem("user", JSON.stringify(user));
}

export function logoutUser() {
  localStorage.removeItem("user");
}

export function isUserLoggedIn() {
  return !!localStorage.getItem("user");
}

export function checkUserRoles(roles: Role[]) {
  const user = JSON.parse(localStorage.getItem("user") || "{}") as User;
  return roles.some((role) => (user.roles || []).includes(role));
}

export async function fetchUser() {
  try {
    const response = await axios.get("users/me");
    if (response?.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
    }
  } catch (error) {
    console.log(error);
  }
}
