import { createFileRoute, redirect } from "@tanstack/react-router";
import { useCustomToken } from "src/hooks/useCustomTheme.ts";
import { Button, Flex, Typography } from "antd";
import { isUserLoggedIn } from "src/utils/user.ts";
import { LandingLayout } from "src/components/LandingLayout.tsx";
import { tenantId } from "src/constants/api.ts";

export const Route = createFileRoute("/")({
  component: Landing,
  beforeLoad: () => {
    if (!isUserLoggedIn()) {
      if (tenantId) {
        throw redirect({
          to: "/login",
        });
      }
    } else {
      throw redirect({
        to: "/dashboard/geopersona/map-view",
      });
    }
  },
});

function Landing() {
  const token = useCustomToken();

  return (
    <LandingLayout>
      <Flex
        vertical
        gap={token.padding4XL}
        style={{ marginTop: token.marginXS }}
      >
        <Flex vertical gap={token.paddingLG}>
          <Typography.Title level={2} style={{ margin: 0 }}>
            Welcome to Echo Platform!
          </Typography.Title>
          <Typography.Title level={1} style={{ margin: 0 }}>
            Reliable, secure and scalable Geospatial data.
          </Typography.Title>
        </Flex>
        <Flex
          gap={token.padding}
          style={{
            width: "100%",
            marginBottom: token.margin3XL,
          }}
        >
          <Button
            type="primary"
            href={"https://www.echo-analytics.com/geopersona-contact"}
            size={"large"}
            block
          >
            Request access
          </Button>
        </Flex>
      </Flex>
      <Flex
        justify={"space-between"}
        wrap="wrap"
        gap={token.padding}
        style={{
          position: "absolute",
          bottom: token.marginXL,
          left: 0,
          right: 0,
          paddingInline: token.paddingXL,
        }}
      >
        <Typography.Text style={{ margin: 0 }}>
          &copy; 2024 Copyright
        </Typography.Text>
        <Flex gap={token.padding} wrap="wrap">
          <Typography.Link
            style={{ color: token.colorWhite, margin: 0 }}
            href={"https://www.echo-analytics.com"}
          >
            Learn more
          </Typography.Link>
          <Typography.Link
            style={{ color: token.colorWhite, margin: 0 }}
            href={"https://www.echo-analytics.com/privacy-policy"}
          >
            Privacy Policy
          </Typography.Link>
        </Flex>
      </Flex>
    </LandingLayout>
  );
}
